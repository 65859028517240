<template>
    <div>
        <div class="accord ">
        <div class="accord__center center">
            <div class="w-page-head">
                <div class="accord__details">
                    <div class="accord__top" data-aos="animation-scale-y" style="margin-bottom: 0;">
                        <div class="accord__title title title_sm">Domande frequenti</div>
                        <div class="accord__text">Le domande più frequenti dei nostri utenti.</div>
                    </div>
                </div>

                    <img src="../assets/faq.svg" alt="FAQ" style="height: 100%; width: auto;"/>
                
            </div>
        </div>
    </div>
        <div class="accord js-accord">
        <div class="accord__center center">
            <div class="accord__row">
                
                <div class="accord__wrap" style="width: 100%;">
                    <div class="accord__item js-accord-item active" >
                        <div class="accord__head js-accord-head" @click="toggleFAQ">
                            <div class="accord__icon"><img class="accord__pic" src="img/layout-3.svg" alt="Responsive "></div>
                            <div class="accord__category">Guida per l'Apertura di un Ticket di Supporto a Wised</div>
                        </div>
                        <div class="accord__body js-accord-body" style="display: block; background-image: url('img/accord-pic.png');">
                            <p class="wised-p">Se sei qui, probabilmente hai bisogno di un po' di aiuto con qualcosa su Wised, giusto? Nessun problema, siamo qui per aiutarti! Segui questa guida semplice e amichevole per aprire un ticket di supporto e risolvere il tuo problema nel minor tempo possibile.</p>
                            <ul class="accord__desc">
                                <ul>
                                    <li><strong class="wised-green">Passo 1:</strong> Invia una Mail al Nostro Supporto Tecnico Innanzitutto, per aprire un ticket di supporto, ti basta inviare una mail a support@wised.it. Ricordati di spiegarci bene qual è il problema che stai riscontrando e di essere il più dettagliato possibile. Più informazioni ci fornisci, meglio possiamo capire come aiutarti!</li>
                                    <li><strong class="wised-green">Passo 2:</strong> Tempo di Attesa? 48 Ore Lavorative! Una volta inviata la tua richiesta, non preoccuparti se non ricevi subito una risposta. Il nostro fantastico team di supporto di primo livello si impegna a prendere in carico la tua richiesta entro 48 ore lavorative. Quindi, un po' di pazienza, ok?</li>
                                    <li><strong class="wised-green">Passo 3:</strong> Riceverai una Mail di Conferma Ecco una buona notizia: non appena avremo preso in carico la tua richiesta, riceverai una mail di conferma apertura. Al suo interno troverai anche il numero del ticket di supporto. Questo numero diventerà il tuo migliore amico se decidi di chiamarci per avere assistenza telefonica!</li>
                                    <li><strong class="wised-green">Passo 4:</strong> Raccontaci Tutto, Davvero Tutto! Quando ci scrivi, cerca di spiegarci il più dettagliatamente possibile qual è il problema che stai riscontrando. Dai tutti gli step che hai fatto fino a quel momento e specifica anche quali dispositivi, browser e tipo di connessione hai utilizzato. Ciò ci aiuterà a capire meglio il problema e a darti una soluzione più rapida ed efficace.</li>
                                    <li><strong class="wised-green">Passo 5:</strong> Allegati Multimediali? Wow, Perfetto! Se hai la possibilità, allega anche materiale multimediale come screenshot o video che documentino il problema. Questo ci darà una visione più chiara di ciò che sta succedendo e ci permetterà di risolvere il problema più rapidamente.</li>
                                </ul>
                            </ul>
                            <p class="wised-p">Ecco fatto! Seguendo questi passaggi semplici, sarai sulla buona strada per risolvere il tuo problema con Wised. Ricorda, siamo qui per aiutarti e faremo del nostro meglio per assicurarci che tu torni a goderti pienamente i nostri servizi. Grazie per aver scelto Wised e buona fortuna con il tuo ticket di supporto!</p>
                        </div>
                    </div>
                    <div class="accord__item js-accord-item" >
    <div class="accord__head js-accord-head" @click="toggleFAQ">
        <div class="accord__icon"><img class="accord__pic" src="img/layout-3.svg" alt="Responsive "></div>
        <div class="accord__category">Guida all'Utilizzo del Nuovo Portale della Webmail Wised</div>
    </div>
    <div class="accord__body js-accord-body" style=" background-image: url('img/accord-pic.png');">
        <p class="wised-p">Siamo entusiasti di introdurre il nuovo portale della <span class="wised-green">webmail Wised</span>, progettato appositamente per offrirvi un'esperienza di posta elettronica ancora migliore. Con questa nuova piattaforma, abbiamo cercato di mettere al centro le vostre esigenze, offrendovi un'interfaccia intuitiva e funzionale per gestire la vostra posta in modo efficiente.</p>
        <p class="wised-p">Il nostro obiettivo con questo nuovo portale è quello di <span class="wised-green">semplificare</span> il modo in cui accedete e interagite con la vostra posta elettronica quotidiana. Abbiamo ascoltato i vostri feedback e abbiamo lavorato duramente per creare una piattaforma che sia <span class="wised-green">facile da usare</span> e ricca di funzionalità utili.</p>
        <p class="wised-p">Siamo consapevoli che la gestione della posta elettronica può essere una sfida, specialmente in un mondo sempre più veloce e connesso. Con il nuovo portale della <span class="wised-green">webmail Wised</span>, speriamo di rendere questa esperienza un po' meno stressante e un po' più piacevole per voi.</p>
        <p class="wised-p">Qui di seguito troverete tutte le informazioni necessarie per iniziare ad utilizzare il nuovo portale senza problemi.</p>
        <h3 class="wised-green">Accesso al Portale:</h3>
        <p class="wised-p">Per accedere al nuovo portale della <span class="wised-green">webmail Wised</span>, seguite questi semplici passaggi:</p>
        <ol class="accord__desc">
            <li>Aprite il vostro browser preferito e digitate l'indirizzo <a href="https://mailbox.wised.it/">https://mailbox.wised.it/</a> nella barra degli indirizzi.</li>
            <li>Una volta caricata la pagina, inserite il vostro nome utente e la password nei campi appositi.</li>
            <li>Cliccate su "Accedi" per completare l'accesso.</li>
        </ol>
        <h3 class="wised-green">Interfaccia del Portale:</h3>
        <p class="wised-p">Una volta effettuato l'accesso, vi troverete nella nuova interfaccia della <span class="wised-green">webmail Wised</span>. Qui potrete:</p>
        <ul class="accord__desc">
            <li>Visualizzare la vostra casella di posta in arrivo con tutti i messaggi ricevuti.</li>
            <li>Utilizzare le varie cartelle (come Posta in arrivo, Posta inviata, Bozze, ecc.) per organizzare i vostri messaggi.</li>
            <li>Comporre nuove email cliccando sul pulsante "Nuovo messaggio".</li>
            <li>Gestire i contatti e creare nuove rubriche per organizzare i vostri contatti.</li>
        </ul>
    </div>
</div>


<div class="accord__item js-accord-item" >
    <div class="accord__head js-accord-head" @click="toggleFAQ">
        <div class="accord__icon"><img class="accord__pic" src="img/layout-3.svg" alt="Responsive "></div>
        <div class="accord__category">Guida all'Utilizzo del Nuovo Portale per la Fatturazione e i Documenti</div>
    </div>
    <div class="accord__body js-accord-body" style="background-image: url('img/accord-pic.png');">
        <p class="wised-p">Siamo <span class="wised-green">entusiasti</span> di introdurre il nostro nuovo Customer Portal, il luogo dove la gestione dei tuoi servizi Wised diventa un'esperienza piacevole e senza stress. Con il customer.wised.it, hai accesso a una serie di funzionalità che renderanno la gestione delle tue fatture, dei pagamenti e del supporto clienti un gioco da ragazzi!</p>
        <p class="wised-p">Prima di tutto, diamo un'occhiata a come puoi tenere traccia delle tue <span class="wised-green">finanze</span> e dei documenti <span class="wised-green">importanti</span>. Una volta effettuato l'accesso al Customer Portal, sarai accolto da un'interfaccia pulita e intuitiva che ti permetterà di visualizzare e scaricare le tue fatture in modo rapido e semplice. Inoltre, potrai tenere d'occhio lo stato dei tuoi pagamenti e aggiungere nuovi metodi di pagamento con pochi clic. Nessuna complicazione, solo trasparenza e facilità d'uso.</p>
        <p class="wised-p">Ma non finisce qui! Con il nostro Customer Portal, hai anche la possibilità di creare i <span class="wised-green">ticket di supporto</span> in modo efficiente. Hai una domanda o hai bisogno di assistenza? Basta creare un ticket direttamente dalla piattaforma e il nostro team di supporto sarà pronto ad aiutarti nel minor tempo possibile. È come avere un canale diretto con il nostro team, pronto a risolvere ogni tua esigenza.</p>
        <p class="wised-p">E il meglio deve ancora venire! Stiamo costantemente lavorando per migliorare la tua esperienza con Wised, quindi non sorprenderti se vedrai nuove funzionalità e miglioramenti nel Customer Portal nel tempo. Il tuo feedback è prezioso per noi, quindi non esitare a condividere le tue opinioni e suggerimenti per rendere la piattaforma ancora migliore.</p>
        <p class="wised-p">Insomma, il nostro Customer Portal è progettato con te in mente, per semplificare la gestione dei tuoi servizi e offrirti un'esperienza senza problemi. Siamo qui per te, ogni passo del cammino.</p>
    </div>
</div>


<div class="accord__item js-accord-item" >
    <div class="accord__head js-accord-head" @click="toggleFAQ">
        <div class="accord__icon"><img class="accord__pic" src="img/layout-3.svg" alt="Responsive "></div>
        <div class="accord__category">Guida all'Utilizzo del Nuovo Portale per la Gestione dei propri Prodotti e Servizi</div>
    </div>
    <div class="accord__body js-accord-body" style="background-image: url('img/accord-pic.png');">
        <p class="wised-p">Siamo <span class="wised-green">entusiasti</span> di presentarvi il nostro nuovo <span class="wised-green">Control Panel</span>, il vostro nuovo compagno di viaggio per gestire i vostri prodotti e servizi Wised in modo semplice e intuitivo. Con <span class="wised-green">controlpanel.wised.it</span>, avete il controllo totale sul vostro dominio, la vostra posta elettronica e molto altro ancora!</p>
        <p class="wised-p">Una volta effettuato l'accesso al <span class="wised-green">Control Panel</span>, vi troverete di fronte a un'interfaccia chiara e intuitiva che vi permetterà di gestire tutti i vostri prodotti e servizi Wised con facilità. Che si tratti di aggiungere nuovi domini, configurare la vostra posta elettronica o effettuare altre modifiche, tutto è a portata di clic.</p>
        <p class="wised-p">Ma non è tutto! Il nostro <span class="wised-green">Control Panel</span> vi offre anche una serie di strumenti e funzionalità per personalizzare e ottimizzare le vostre risorse digitali. Avete bisogno di impostare un nuovo indirizzo email o aggiungere un alias al vostro dominio? Nessun problema, potete farlo facilmente attraverso il <span class="wised-green">Control Panel</span>, senza dovervi preoccupare di procedure complicate.</p>
        <p class="wised-p">Inoltre, siamo qui per aiutarvi in ogni passo del processo. Se avete domande o avete bisogno di assistenza, il nostro team di supporto è sempre a vostra disposizione per guidarvi e risolvere qualsiasi problema possa sorgere. La vostra soddisfazione è la nostra priorità numero uno, e faremo tutto il possibile per garantire che abbiate un'esperienza positiva con il nostro <span class="wised-green">Control Panel</span>.</p>
        <p class="wised-p">Insomma, con il nostro <span class="wised-green">Control Panel</span> avete il controllo totale sul vostro mondo digitale. È come avere il vostro personale assistente virtuale, pronto ad aiutarvi a fare tutto ciò che desiderate. Siamo qui per rendere la vostra vita più facile e più produttiva, passo dopo passo.</p>
    </div>
</div>


                    
                </div>
            </div>
        </div>
    </div>

    

    
    <div class="tool">
        <div class="tool__center center">
            <div class="tool__container">
                <div class="tool__wrap">
                    <h2 class="tool__title title" style="width: 100%;">Sei già dei nostri? <br /> Consulta i tuoi documenti.</h2>
                    <div class="tool__info info" >Consulta i tuoi documenti. </div>
                    <div class="tool__btn" ><a class="btn btn_white js-popup-open" href="https://customer.wised.it/" data-effect="mfp-zoom-in">Area riservata</a></div>
                </div>
                <div class="tool__cloud"><img class="tool__pic js-parallax" data-scale="1.5" data-orientation="right" src="img/cloud.png" alt="Cloud "></div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    name: 'FAQ',
    created() {
        
        
    },

    computed: {
        isMobile() {
            return window.innerWidth < 768;
        }
    },

    methods: {
        toggleFAQ(e){


            // find active item
            var activeItem = window.$(e.target).closest('.js-accord-item');
            // slide it up
            activeItem.find('.js-accord-body').slideToggle();
            // remove active class
            activeItem.toggleClass('active');

            // scroll to begin of clicked item only from mobile
            if(window.innerWidth < 768){
                var top = activeItem.offset().top;
                window.$('html, body').animate({
                    scrollTop: top
                }, 500);
            }

        }
    }

}
</script>

<style scoped>
.accord__item:not(.active) .accord__head {
    background-color: #ECF2F6!important;
    color: black!important;
}

.w-page-head {
    height: 220px; display: flex; justify-content: space-between; align-items: center;
}

.w-page-head .accord__details {
    margin: 0!important;
    padding: 0!important;
}

.accord__desc {
    margin-top: 15px;
    margin-bottom: 15px;
}

.accord__desc li {
    color: #52565f!important;
}

.wised-green {
    color: #00A3E0;
}

.wised-p {
    color: #999FAE;
}

/* from mobile align bottom and img must be 50% */
@media (max-width: 768px) {
    .w-page-head {
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
    }

    .w-page-head img {
        width: 90%!important;
        height: unset!important;
    }
}
</style>